<template>
  <div>
    <map-search @save-image="saveImage" />
    <status-google-map :save-image-watcher="saveImageWatcher" />
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import mapStoreModule from '@/views/dashboard/map/mapStoreModule'
import html2canvas from 'html2canvas'
import MapSearch from './MapSearch.vue'
import StatusGoogleMap from './StatusGoogleMap.vue'

export default {
  components: {
    StatusGoogleMap,
    MapSearch,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-map'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, mapStoreModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const saveImageWatcher = ref(null)

    const saveImage = value => {
      saveImageWatcher.value = value
    }

    return {
      saveImageWatcher,

      saveImage,
    }
  },
}
</script>
