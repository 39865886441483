import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSearchResults(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/customers/search-for-map-suggests', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSearchReferenceNo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/customers/search-for-map-reference-no', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/customers/search-inside-bounds', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataForLocations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/apps/map/data-for-locations', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
