<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="10"
          class="mb-md-0 mb-2"
        >
          <consumer-search />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-md-0 mb-2"
        >
          <b-button
            v-if="$can('download', 'map')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="emitSaveAsImage()"
          >
            Save as Image
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton, BRow, BCol,
} from 'bootstrap-vue'
import ConsumerSearch from '@/@leecom/consumer-search/ConsumerSearch.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BRow,
    BCol,
    ConsumerSearch,
  },
  directives: {
    Ripple,
  },
  methods: {
    emitSaveAsImage() {
      this.$emit('save-image', moment().valueOf())
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
#autosuggest-autosuggest__results {
  .autosuggest__results {
    padding: 0.2rem;

    li {
      padding: 0.2rem;
      margin: 0.4rem;
      cursor: pointer;
      border-bottom: 1px solid $border-color;
    }
  }
  .autosuggest__results-before {
    color: $text-muted;
    margin: 0.2rem;
  }
}
</style>
