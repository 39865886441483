var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"w-full h-[calc(100vh-360px)] min-h-[400px]",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"p-0"},[_c('div',{ref:"printArea",staticClass:"print-block h-full w-full"},[_c('div',{staticClass:"print-bordered h-full w-full"},[_c('div',{staticClass:"flex flex-col h-full"},[_c('div',{staticClass:"h-[80px] flex"},[_c('img',{staticClass:"p-1 h-[80px] flex",attrs:{"src":require('@/assets/images/icons/north-icon.png')}}),_c('div',{staticClass:"flex-grow"},[_c('img',{staticClass:"p-[0.4rem] h-[80px] mx-auto left-[20px] relative",attrs:{"src":require('@/assets/images/logo/logo-full-horizontal-cropped-1200.png')}})]),_c('div',{staticClass:"w-[80px]"},[_c('h6',[_vm._v(_vm._s(_vm.printDate))])])]),_c('div',{staticClass:"flex-grow flex"},[_c('GmapMap',{ref:"gmap",staticClass:"w-full h-full",attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
                  styles: _vm.mapStyles,
                  streetViewControl: false,
                  mapTypeControlOptions: {
                    mapTypeIds: [
                      _vm.google.maps.MapTypeId.ROADMAP,
                      _vm.google.maps.MapTypeId.SATELLITE ],
                  },
                }},on:{"zoom_changed":_vm.zoomChanged,"bounds_changed":_vm.boundsChanged}},[_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"label":m.label,"clickable":true,"icon":_vm.icon},on:{"click":function($event){return _vm.toggleInfoWindow(m, index)}}})}),_c('GmapInfoWindow',{attrs:{"options":_vm.infoWindowOptions,"position":_vm.infoWindowPosition,"opened":_vm.infoWindowOpen},on:{"closeclick":function($event){_vm.infoWindowOpen = false}}})],2)],1)])])])])],1),_c('busy-modal',{attrs:{"card":_vm.busyCard}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }