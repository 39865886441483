<template>
  <div>
    <b-card no-body class="w-full h-[calc(100vh-360px)] min-h-[400px]">
      <b-card-body class="p-0">
        <div ref="printArea" class="print-block h-full w-full">
          <div class="print-bordered h-full w-full">
            <div class="flex flex-col h-full">
              <div class="h-[80px] flex">
                <img
                  :src="require('@/assets/images/icons/north-icon.png')"
                  class="p-1 h-[80px] flex"
                />
                <div class="flex-grow">
                  <img
                    class="p-[0.4rem] h-[80px] mx-auto left-[20px] relative"
                    :src="
                      require('@/assets/images/logo/logo-full-horizontal-cropped-1200.png')
                    "
                  />
                </div>
                <div class="w-[80px]">
                  <h6>{{ printDate }}</h6>
                </div>
              </div>
              <div class="flex-grow flex">
                <GmapMap
                  ref="gmap"
                  :center="center"
                  :zoom="zoom"
                  class="w-full h-full"
                  :options="{
                    styles: mapStyles,
                    streetViewControl: false,
                    mapTypeControlOptions: {
                      mapTypeIds: [
                        google.maps.MapTypeId.ROADMAP,
                        google.maps.MapTypeId.SATELLITE,
                      ],
                    },
                  }"
                  @zoom_changed="zoomChanged"
                  @bounds_changed="boundsChanged"
                >
                  <GmapMarker
                    v-for="(m, index) in markers"
                    :key="index"
                    :position="m.position"
                    :label="m.label"
                    :clickable="true"
                    :icon="icon"
                    @click="toggleInfoWindow(m, index)"
                  />
                  <GmapInfoWindow
                    :options="infoWindowOptions"
                    :position="infoWindowPosition"
                    :opened="infoWindowOpen"
                    @closeclick="infoWindowOpen = false"
                  />
                </GmapMap>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <busy-modal :card="busyCard" />
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import {
  ref,
  watch,
  computed,
  onUnmounted,
  onMounted,
} from '@vue/composition-api'
import store from '@/store'
import { BCard, BCardBody, BCardText, BAvatar, BRow, BCol } from 'bootstrap-vue'
import appIcons from '@leecom/app-icons'
import { replaceUndefined } from '@leecom/utils/filter'
import html2canvas from 'html2canvas'
import { showErrorToast } from '@leecom/utils/support'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import BusyModal from '@/@leecom/busy-modal/BusyModal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BAvatar,
    BCardBody,
    BRow,
    BCol,
    BusyModal,
  },
  props: {
    saveImageWatcher: {
      required: true,
    },
  },
  setup(props, { root: $this }) {
    const toast = useToast()

    const busyCard = ref({
        state: null,
    })

    const gmap = ref(null)
    const google = computed(() => getGoogleMapsAPI())
    const center = ref({ lat: 26.041426562256525, lng: 50.54042061533276 })
    const zoom = ref(11)

    const mapStyles = ref([
      {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
          {
            color: '#878787',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f9f5ed',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#aee0f4',
          },
        ],
      },
    ])

    const map = ref(null)
    const markers = ref([])
    const locations = ref([])
    const icon = ref(null)
    const dataForLocations = ref(null)
    const icons = ref(appIcons)

    const infoWindowOpen = ref(false)
    const infoWindowPosition = ref(null)
    const infoWindowOptions = ref({
      content: '',
      // optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 15,
        height: -5,
      },
    })
    const currentMarkerIdx = ref(null)

    const zoomChanged = zoom => {}
    const boundsChanged = event => {}

    const _renderInfo = location => `
    <div>
      <div>
        <table class="app-map-popup-table">
          <tr>
            <td><b>Reference No:</b></td>
            <td>${location.reference_no}</td>
          </tr>
          <tr>
            <td><b>Serial No:</b></td>
            <td>${location.serial_no}</td>
          </tr>
           <tr>
            <td><b>Well No:</b></td>
            <td>${location.well_no}</td>
          </tr>
          <tr>
            <td><b>Consumer:</b></td>
            <td>${location.consumer_name}</td>
          </tr>
          <tr>
            <td><b>Address:</b></td>
            <td>${location.building} ${location.road}  ${location.block} ${
      location.area
    }</td>
          </tr>
          <tr>
            <td><b>Total:</b></td>
            <td>${replaceUndefined(location.total_plus)}</td>
          </tr>
          <tr>
            <td><b>Flow Rate:</b></td>
            <td>${replaceUndefined(location.actual_flow)}</td>
          </tr>
          <tr>
            <td><b>Batteries:</b></td>
            <td class="inline-flex">
            <span class="self-center">Main: ${replaceUndefined(
              location.main_battery,
            )}</span>
             <img class="ml-[0.2rem] w-[32px]" title="${
               location.main_battery_state_title
             }" src="${
      icons.value[
        location.main_battery_state == undefined
          ? 'battery-default'
          : `battery-${location.main_battery_state}`
      ]
    }"/>


            <span class="self-center">| 3G/4G: ${replaceUndefined(
              location.gsm_battery,
            )}</span>
             <img class="ml-[0.2rem] w-[32px]" title="${
               location.gsm_battery_state_title
             }" src="${
      icons.value[
        location.gsm_battery_state == undefined
          ? 'battery-default'
          : `battery-${location.gsm_battery_state}`
      ]
    }"/>
</td>
          </tr>
           <tr>
            <td><b>Last Message Date:</b></td>
            <td>${replaceUndefined(location.last_communication_at)}</td>
          </tr>
        </table>
      </div>
    </div>
    `
    const _getLocations = () => store.dispatch('app-map/fetchLocations', {})
    const _getData = () => store.dispatch('app-map/fetchDataForLocations', {})

    const _setMarkers = () => {
      markers.value = _.map(locations.value, item => {
        const infoText = _renderInfo(item)
        return {
          position: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
          infoText,
          label: {
            text: item.reference_no,
            className: 'app-marker-label',
          },
          item,
        }
      })
    }

    const drawMarkers = () =>
      new Promise((resolve, reject) => {
        Promise.all([_getLocations(), gmap.value.$mapPromise])
          .then(values => {
            locations.value = values[0].data.results
            map.value = values[1]

            _setMarkers()
            resolve(locations)
          })
          .catch(error => {
            reject(error)
          })
      })

    const toggleInfoWindow = (marker, idx) => {
      // NOTE:: removed info window and make fixed : 2022-08-31
      // infoWindowPosition.value = marker.position
      // infoWindowOptions.value.content = marker.infoText

      // if (currentMarkerIdx.value == idx) {
      //   infoWindowOpen.value = !infoWindowOpen.value
      // } else {
      //   infoWindowOpen.value = true
      //   currentMarkerIdx.value = idx
      // }
      _createFixedInfoWindow(marker, idx);
    }

    $this.$gmapApiPromiseLazy().then(() => {

      Promise.all([drawMarkers(), _getData()])
        .then(values => {
          dataForLocations.value = values[1].data.results
          locations.value = _.map(locations.value, location => {
            const data = _.find(dataForLocations.value, {
              serial_no: location.serial_no,
            })
            if (data !== undefined) {
              const merged = { ...location, ...data }
              return merged
            }
            console.log('no data locations', location.reference_no)

            return location
          })

          _setMarkers()

          // _createFixedInfoWindow(map.value);
        })
        .catch(() => {})

      icon.value = {
        url: require('@/assets/images/icons/map-maker-meter.png'),
        scaledSize: new google.value.maps.Size(36, 36), // scaled size
        origin: new google.value.maps.Point(0, 0), // origin
        anchor: new google.value.maps.Point(0, 0), // anchor
      }
    })

    $this.$root.$on('app-consumer-search-selected', event => {
      zoom.value = 21
      setTimeout(() => {
        center.value = {
          lat: Number(event.latitude),
          lng: Number(event.longitude),
        }
        zoom.value = 20
      }, 300)
    })

    watch(
      () => props.saveImageWatcher,
      value => {
        saveImage()
      },
    )

    const printArea = ref(null)
    const printDate = ref(null)

    const saveImage = () => {
       busyCard.value.state = 'WAITING';
      printDate.value = 'Print Date ' + moment().format('MM/DD/YY')
      setTimeout(() => {
        html2canvas(printArea.value, {
          logging: true,
          allowTaint: true,
          useCORS: true,
          backgroundColor: '#ffffff',
          onclone(doc) {
            const td = doc.querySelectorAll('td')
            td.forEach(item => {
              item.style['padding-top'] = '0.0rem'
              item.style['padding-bottom'] = '0.4rem'
            })
          },
          ignoreElements(element) {
            if (
              element.getAttribute('alt') == 'Google' ||
              element.getAttribute('title') == 'Close'
            ) {
              return true
            }

            if (
              element.classList.contains('gmnoprint') ||
              element.classList.contains('gmnoscreen') ||
              element.classList.contains('gm-fullscreen-control')
            ) {
              return true
            }
          },
        })
          .then(canvas => {
            const fileName = 'map.jpg'
            const fileURL = canvas.toDataURL('image/jpg')
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)

            fileLink.click()

            printDate.value = null
            busyCard.value.state = null;
          })
          .catch(error => {
            printDate.value = null
            busyCard.value.state = null;
            console.log(error)
            showErrorToast(toast, error, 'Unable save as image')
          })
      }, 1000)
    }

    const _createFixedInfoWindow = (marker, index) => {
      _removeFixedInfoWindow();
      const controlDiv = document.createElement('div');
      controlDiv.setAttribute("class", `map-fixed-info`);
      _addFixedInfoWindow(controlDiv, marker.item);
      map.value.controls[google.value.maps.ControlPosition.LEFT_BOTTOM].push(
        controlDiv
      );
    }

    const _addFixedInfoWindow = (controlDiv, location) => {
      const controlUI = document.createElement('div');

      controlUI.innerHTML = _renderInfo(location);

      const closeDiv = `
      <div title="Close" style="
          cursor: pointer;
          margin-left: auto;
          margin-right: 0;
          position: absolute;
          top: 0;
          right: 0;
      ">
      <span style="-webkit-mask-image: url(&quot;data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E&quot;);pointer-events: none;display: block;width: 14px;height: 14px;margin: 8px;background-color: #000;"></span>
      </div>
      `
      const closeUI = document.createElement('div');

      closeUI.innerHTML = closeDiv;

      controlDiv.appendChild(closeUI)

      controlDiv.appendChild(controlUI);

      closeUI.addEventListener('click', () => {
        console.log(123);

        _removeFixedInfoWindow();
      });
    }

    const _removeFixedInfoWindow = () => {
      const mdArray = map.value.controls[google.value.maps.ControlPosition.LEFT_BOTTOM];

      mdArray.forEach((item, index) => {
        if(item.className == 'map-fixed-info'){
          mdArray.removeAt(index);
        }
      })
    }

    return {
      center,
      zoom,
      mapStyles,

      markers,
      icon,
      icons,

      infoWindowOpen,
      infoWindowPosition,
      infoWindowOptions,

      toggleInfoWindow,

      google,
      gmap,

      zoomChanged,
      boundsChanged,

      printArea,
      printDate,

      busyCard,
    }
  },
}
</script>

<style>
.app-marker-label {
  position: absolute;
  color: #1a73e8 !important;
  white-space: break-spaces;
  left: -120px;
  text-shadow: -1px 0px 1px black;
  width: 100px;
  text-align: right;
}

.app-map-popup-table tr:nth-of-type(even) {
  background: #ebe9f1;
}

.app-map-popup-table tr td {
  padding: 0.2rem;
}

.gm-style .gm-style-iw-c {
  box-shadow: none !important;
  overflow: initial !important;
}

.map-fixed-info{
  background: #ffffff;
  padding: 1rem;
  border-radius: 0.4rem;
}
</style>

<style lang="scss" scoped>
.print-block {
  padding: 2rem;
  position: relative;

  .print-bordered {
    border: 3px solid #000;
  }
}
</style>
